import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import HomePage from './NewComponents/Homepage';
import Materials from './NewComponents/Materials';
import AboutUs from './NewComponents/AboutUs';
import UploadPage from './NewComponents/UploadPage/UploadPage';
import Cart from './NewComponents/CartPage/Cart';
import Options from './NewComponents/Options/Options';
import HttpPopup from './NewComponents/HTTPPopup/HttpPopup';
import { HttpPopupContext } from './NewComponents/HTTPPopup/HttpPopupContext';
import SuccessfulCheckout from './NewComponents/SuccessfulCheckout';
import TermsOfUse from './NewComponents/UploadPage/TermsOfUse';
import PrivacyPolicy from './NewComponents/UploadPage/PrivacyPolicy';
import FAQ from './NewComponents/FAQ';
import HowToGet3DModels from './NewComponents/HowToGet3DModels'

// Function to load the Google tag
function loadGoogleTag() {
  const tagId = 'AW-865538075';

  const scriptTag = document.createElement('script');
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
  scriptTag.async = true;
  document.head.appendChild(scriptTag);

  const scriptInline = document.createElement('script');
  scriptInline.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${tagId}');
  `;
  document.head.appendChild(scriptInline);
}

// Custom hook to track page views
function useTrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    window.gtag && window.gtag('config', 'AW-865538075', {
      page_path: location.pathname + location.search,
    });
  }, [location]);
}

function App() {
  const [cart, setCart] = useState();
  const [STLArray, setSTLArray] = useState([]);
  const [response, setResponse] = useState(false);

  useEffect(() => {
    loadGoogleTag();
  }, []);

  return (
    <HttpPopupContext.Provider value={{ response, setResponse }}>
      <div className="App">
        <Router>
          <TrackPageViews />
          <HttpPopup />
          <Navbar expand="lg" bg="light" className="w-100">
            <LinkContainer to="/">
              <Navbar.Brand className='PrintforgersLogo'>
                <img src={`${process.env.PUBLIC_URL}/printforgers.com-logo-smaller.webp`} alt="PrintForgers.com" />
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto custom-nav-flex">
                <LinkContainer to="/upload">
                  <Nav.Link>Instant Quote</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/howtoget3dmodels">
                  <Nav.Link>How to Get 3D Models</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                  <Nav.Link>About Us</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/faq">
                  <Nav.Link>FAQ</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cart">
                  <Nav.Link>Cart</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path="/upload" element={<UploadPage STLArray={STLArray} setSTLArray={setSTLArray} />} />
            <Route path="/howtoget3dmodels" element={<HowToGet3DModels />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/options" element={<Options STLArray={STLArray} setSTLArray={setSTLArray} cart={cart} setCart={setCart} />} />
            <Route path="/cart" element={<Cart cart={cart} setCart={setCart} />} />
            <Route path="/successfulcheckout" element={<SuccessfulCheckout />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Catch all undefined routes and redirect to the homepage */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
    </HttpPopupContext.Provider>
  );
}

// Component to track page views
function TrackPageViews() {
  useTrackPageViews();
  return null;
}

export default App;
